import { Controller } from "stimulus"

export default class extends Controller {
    static values = { uuid: String }
    connect() {
        const scriptUrl = document.getElementById('spp-checkout-form').value
        console.log("Script value is: " + scriptUrl)
        this.loadScript(scriptUrl)
            .then(() => {
                console.log("Initializing checkout!")
                this.initializeSPPCheckout();
            })
            .catch((error) => {
                console.error("Error loading script:", error);
            });

        this.checkAgreements()
        this.radioBtnPreventDefaultAndOpenModal()
    }

    initializeSPPCheckout() {
        if(document.getElementById('spp-checkout-app')) {
            const config = {
                elementId: "spp-checkout-app",
                token: document.getElementById('spp-token').value,
                publicToken:  document.getElementById('spp-public-token').value,
                returnUrl: document.getElementById('confirmation-url').value,
                onSuccess: (message) => {
                this.confirmationEmail()
                },
                onError: (message) => {
                    console.log(message)
                },

            };
            SPPCheckout.addCheckoutForm(config);
        } else {
            const config = {
                publicToken: document.getElementById('spp-public-token').value,
            };
            SPPCheckout.addCheckoutForm(config);
        }
    }

    loadScript(url) {
        return new Promise((resolve, reject) => {
            // Check if the script is already loaded
            if (document.querySelector(`script[src="${url}"]`)) {
                console.log("Script already loaded.");
                resolve();
                return;
            }

            // If not loaded, create a new script element and load it
            const script = document.createElement("script");
            script.type = "module"
            script.src = url;
            script.onload = resolve;
            script.onerror = reject;
            document.head.appendChild(script);
    })
    }

    openModal(event) {
        event.preventDefault()
        const sppModalElement = document.getElementById('spp-modal')
        sppModalElement.classList.add("show");
    }

    closeModal() {
        const sppModalElement = document.getElementById('spp-modal')
        sppModalElement.classList.remove("show");
    }

    async agreeAndClose() {
        let checkboxElem = document.getElementById('warranty_quote_payment_agreement');
        checkboxElem.removeAttribute("data-action")
        if (checkboxElem.checked !== true) {
            checkboxElem.checked = true
            let event = new Event('change');
            checkboxElem.dispatchEvent(event);
        }
        this.closeModal()
    }

    checkAgreements() {
        const contactCheckbox = document.getElementById('sppContact')
        if (contactCheckbox === null) {
            return "";
        }

        const contractCheckbox = document.getElementById('sppRetailContract')
        const sppAgreementsBtn = document.getElementById('sppAgreementsBtn')

        if (contactCheckbox.checked && contractCheckbox.checked) {
            sppAgreementsBtn.classList.remove('disabled')
            sppAgreementsBtn.classList.remove('gray-btn')
        } else {
            sppAgreementsBtn.classList.add('disabled')
            sppAgreementsBtn.classList.add('gray-btn')

        }
    }

    downloadAuthAgreement(event) {
        event.preventDefault();
        this.checkAuthPdfStatus();
    }

    checkAuthPdfStatus(startTime = Date.now()) {
        fetch(`/warranty_quotes/${this.uuidValue}/pdf_auth_agreement_status`)
            .then(response => response.json())
            .then(data => {
                if (data.status === 'ready') {
                    window.location.href = data.url;
                } else {
                    const currentTime = Date.now();
                    const elapsedTime = currentTime - startTime;
                    if (elapsedTime < 7000) {
                        setTimeout(() => this.checkAuthPdfStatus(startTime), 500); // Poll every 0.5 seconds
                    } else {
                        console.log('Polling stopped after 3 seconds');
                        // Optionally, handle the timeout case here
                        alert("There was an error generating pdf document.")
                    }
                }
            });
    }

    downloadPaymentAgreement() {
        event.preventDefault();
        this.checkPaymentPdfStatus();
    }

    checkPaymentPdfStatus(startTime = Date.now()) {
        fetch(`/warranty_quotes/${this.uuidValue}/pdf_payment_agreement_status`)
            .then(response => response.json())
            .then(data => {
                if (data.status === 'ready') {
                    window.location.href = data.url;
                } else {
                    const currentTime = Date.now();
                    const elapsedTime = currentTime - startTime;
                    if (elapsedTime < 7000) {
                        setTimeout(() => this.checkPaymentPdfStatus(startTime), 500); // Poll every 0.5 seconds
                    } else {
                        console.log('Polling stopped after 3 seconds');
                        alert("There was an error generating pdf document.")
                    }
                }
            });
    }


    radioBtnPreventDefaultAndOpenModal() {
        const paymentRadio = document.getElementById("warranty_quote_payment_agreement_true")
        if (paymentRadio) {
            paymentRadio.addEventListener('click', (event) => {
               event.preventDefault()
               this.openModal()
            });
        }
    }

     confirmationEmail() {
        const uuid = new URL(window.location.href).searchParams.get("uuid")
        fetch('/background-checkout', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                warranty_quote_uuid: uuid
            }),
        })
            .then((r) => r.json())
            .catch(() =>
                console.log('There was an error processing the email    .')
            )
    }

}
